const addDecimals  = require("./addDecimals");

function numberWithCommas(x) {
    if(x !== undefined){
    return addDecimals(x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    }else {
        return "";
    }
}

export default numberWithCommas;