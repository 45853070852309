import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import "firebase/app";

var firebaseConfig = {
  apiKey: "AIzaSyCK1pYiE1Rw5FqsNRWc7ekt6Pv9tQ5lOHM",
  authDomain: "sanifersystem-eb338.firebaseapp.com",
  projectId: "sanifersystem-eb338",
  storageBucket: "sanifersystem-eb338.appspot.com",
  messagingSenderId: "528025645067",
  appId: "1:528025645067:web:e742d479804e6113c4d70e",
  measurementId: "G-H3J2MFKF8X"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;