import React from "react";
import profile_img from "../res/profile-icon.png";
import Switch from "react-switch";

export default function CompanyItem(props) {
    return <div key={props.key} className={`client-item shadow-sm mt-3 ${props.createOrder === true?"client-item-selectable":""}`} onClick={props.createOrder?props.onClick:() => {}}>
        {props.selected?<div className="backdrop-selected">
            <i className="material-icons align-middle">task_alt</i>
        </div>:null}

        <div className="client-body">
        <div className="row">
            <div className="col-3 pt-4">
                <div className="flex-center">
                    <img src={props.user.imagenUrl !== undefined?props.user.imagenUrl:profile_img} className="profile_img" />
                </div>
            </div>
            <div className="col-9 pt-4">
                <p className="client-item-text"><b>{props.user.nombreNegocio}</b></p> 
                <p className="client-item-text mt-2">{props.user.vendedorCreador}</p>
                <p className="client-item-text mt-2">Teléfono: {props.user.celular}</p>
                <p className="client-item-text mt-2">Correo: {props.user.email}</p>
            </div>
        </div>
        </div>

        {props.createOrder === undefined || props.createOrder === false?<div className="client-footer">
            <div className="flex-end">
            <Switch onColor="#3daaaf" checkedIcon={false} uncheckedIcon={false} onChange={(value) => {
                //console.log(value);
                props.changeState(props.user.id, value)
            }} checked={props.user.activo} />
            </div>
        </div>:null}

        {props.createOrder == undefined || props.createOrder === false?<div className="action-buttons">
            <div className="flex-center">
                <button className=" btn " onClick={() => {
                    props.view();
                }}>
                    <i className="material-icons align-middle">visibility</i>
                </button>
                <button className=" btn" onClick={() => {
                    props.edit();
                }}>
                    <i className="material-icons align-middle">create</i>
                </button>
                <button className="btn" onClick={() => {
                    props.delete();
                }}>
                    <i className="material-icons align-middle">clear</i>
                </button>
            </div>
        </div>:null}
    </div>
}