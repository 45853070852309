import React from "react";
import Loading from "./Loading";
import CompleteLoading from "./CompleteLoading";
import firebase from "../utils/firebaseSetUp";
import OrderItem from "./OrderItem";
import ClientItem from "./ClientItem";

import product_placeholder from "../res/product-placeholder.png";

import formatNumber from "../utils/formatNumber"

import Switch from "react-switch";

import KeywordGeneration from "../utils/keywordsGeneration/keywordsGeneration";

import $ from "jquery";

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


export default class Orders extends React.Component {
    _ordersListener;
    _mounted;
    constructor(props){
        super(props);

        this.state = {
            completeLoading:"",
            orders:[],
            loadMore:false,
            lastDocument:null,
            loadMorePending:false,
            search:"",
            screen:0,
            data:null,

            clientSelected:null,

            pendingClients:false,
            loadMoreClients:false,
            pendingLoadMoreClients:false,
            lastDocumentClients:null,
            clients:[],


            pendingProducts:false,
            loadMoreProducts:false,
            pendingLoadMoreProducts:false,
            lastDocumentProducts:null,
            products:[],


            searchProducts:"",
            searchClients:"",
            iva:false,

            cart:[],
            comment:"",
            ivaValue:null,
            factorUSD:null,
            paymentType:"0"
        }
    }


    createOrder = () => {
        let flag = 0;
        let messages = [];

        if(this.state.clientSelected === null){
            flag = 1;
            messages.push("No hay ningún cliente seleccionado")
        }

        if(this.state.cart.length === 0){
            flag = 1;
            messages.push("No hay ningún producto seleccionado")
        }

        if(flag === 0){
            if(this._mounted){
                this.setState({
                    completeLoading:"Creando Pedido..."
                })
            }

            firebase.firestore().collection("ventas").add({
                creado:new Date(),
                actualizado:new Date(),
                comentario:this.state.comment,
                contieneIva:this.state.iva,
                estadoAprobacion:"pendiente",
                factorUSD:this.state.factorUSD,
                formaPago:Number(this.state.paymentType),
                idCliente:this.state.clientSelected.id,
                ivaFactor:this.state.ivaValue,
                nombreCliente:this.state.clientSelected.nombreNegocio,
                nombreVendedor:this.props.userData.nombre,
                observaciones:"",
                palabrasClave:KeywordGeneration.generateKeywords(this.state.clientSelected.nombreNegocio)
                .concat(KeywordGeneration.generateKeywords(this.props.userData.nombre)),
                productos:this.state.cart,
                vendedor:this.props.userData.correo,
                subTotal:(() => {
                    let total= 0;

                    for(let i=0; i < this.state.cart.length; i++){
                        total = total + (this.state.cart[i].precioUSD * this.state.cart[i].cantidad)
                    }

                   // formatNumber(Math.round((total + total*this.state.ivaValue)*100)/100)

                    if(this.state.iva === true){
                        return Math.round((total) * 100)/100
                    }else {
                        return (Math.round(total * 100)/100)

                    }


                })(),
                total:(() => {
                    let total= 0;

                    for(let i=0; i < this.state.cart.length; i++){
                        total = total + (this.state.cart[i].precioUSD * this.state.cart[i].cantidad)

                     
                    }

                    

                    if(this.state.iva === true){
                        return Math.round((total + total*this.state.ivaValue)*100)/100
                    }else {
                        return Math.round(total * 100)/100
                    }


                })(),
                razonSocial:this.state.clientSelected.razonSocial,
                subTotalUSD:(() => { 
                    let total= 0;

                    for(let i=0; i < this.state.cart.length; i++){
                        total = total + (this.state.cart[i].precioUSD * this.state.cart[i].cantidad)
                    }

                   // formatNumber(Math.round((total + total*this.state.ivaValue)*100)/100)

                    if(this.state.iva === true){
                        return Math.round((total) * 100)/100
                    }else {
                        return (Math.round(total * 100)/100)

                    }


                })()*this.state.factorUSD,
                totalUSD:(() => {
                    let total= 0;

                    for(let i=0; i < this.state.cart.length; i++){
                        total = total + (this.state.cart[i].precioUSD * this.state.cart[i].cantidad)

                     
                    }

                    

                    if(this.state.iva === true){
                        return Math.round((total + total*this.state.ivaValue)*100)/100
                    }else {
                        return Math.round(total * 100)/100
                    }


                })()*this.state.factorUSD
            })
            .then((d) => {
                if(this._mounted){
                    this.setState({
                        completeLoading:"",
                        clientSelected:null,
                        cart:[],
                        paymentType:"0",
                        screen:0
                    })
                }

                

                this.props.addToast("Pedido creado")

                $.ajax({url:"https://sanifer-ventas.herokuapp.com/sendEmail", crossDomain:true, 
                            data:{
                               type:"sale",
                               id:d.id,
                               email:this.props.userData.correo
                            },
                           method:"POST",
                        success:(response) =>{
                            
                      
                        }, error:(err) => {
                        
                            
                            console.log(err);

                            if(this._mounted){
                                this.setState({
                                    completeLoading:""
                                })
                            }

                            //this.props.addToast()
                           
                        }})
            })
            .catch(e => {
                console.log(e);
                this.props.addToast("Algo salió mal");

                if(this._mounted){
                    this.setState({
                        completeLoading:""
                    })
                }
            })
        }else {
            for(let i =0; i < messages.length; i++){
                this.props.addToast(messages[i])
            }
        }
    }

    getIva = () => {
        firebase.firestore().collection("factores").doc("documentoFactores").get()
        .then(doc => {
            if(this._mounted){
                this.setState({
                    ivaValue:doc.data().iva,
                    factorUSD:doc.data().factorUSD
                })
            }
        })
        .catch(e => {
            console.log(e)
        })
    }

    getClients = () => {
        if(this._mounted){
            this.setState({
                pendingClients:true
            })
        }

        if(this._clientsListener !== undefined){
            this._clientsListener();
        }

        let base =firebase.firestore().collection("clientes");

        if(this.state.searchClients !== ""){
            base = base.where("palabrasClave","array-contains", String(this.state.searchClients).toLowerCase()).orderBy("creado","desc")
        }else {
            base = base.where("vendedorCreador","==",this.props.userData.correo).orderBy("creado","desc")
        }

        base = base.limit(10);

      base.get().then(snap => {
            let users = [];

            snap.forEach(doc => {
                let data = doc.data();
                data.id = doc.id;
                users.push(data);

                
            })

            if(this._mounted){
                this.setState({
                    clients:users,
                    loadMoreClients:snap.docs.length < 10?false:true,
                    loadMorePendingClients:false,
                    lastDocumentClients:snap.docs[snap.docs.length - 1],
                    pendingClients:false
                })
            }
        }).catch(e => {
            console.log(e);
            this.props.addToast("Algo salió mal");

            if(this._mounted){
                this.setState({
                    loadMoreClients:false,
                    loadMorePendingClients:false,
                    lastDocumentClients:null,
                    pendingClients:false
                })
            }
        })

    }

    loadMoreClients = () => {
        if(this._mounted){
            this.setState({
                loadMorePending:true
            })
        }

        let base =firebase.firestore().collection("clientes");

        if(this.state.searchClients !== ""){
            base = base.where("palabrasClave","array-contains", this.state.searchClients).orderBy("creado","desc")
        }else {
            base = base.where("vendedorCreador","==",this.props.userData.correo).orderBy("creado","desc")
        }

        base = base.startAfter(this.state.lastDocument).limit(10);

        base.get()
        .then(snap => {
            let users = [];

            snap.forEach(doc => {
                let data = doc.data();
                data.id = doc.id;

                users.push(data);
            })

            if(this._mounted){
                this.setState({
                    clients:this.state.clients.concat(users),
                    loadMore:snap.docs.length < 10?false:true,
                    lastDocument:snap.docs[snap.docs.length - 1],
                    pending:false,
                    loadMorePending:false
                })
            }
        })
        .catch(e => {
            console.log(e);
            if(this._mounted){
                this.setState({
                    loadMore:false,
                    loadMorePending:false
                })
            }
        })
    }

    loadMoreOrders = () => {
        if(this._mounted){
            this.setState({
               loadMorePending:true
            })
         }
 
         
         let base = firebase.firestore().collection("ventas");
 
         if(this.state.search !== ""){
             base = base.where("palabrasClave","array-contains",String(this.state.search).toLowerCase());
         }
 
         base.where("vendedor","==",this.props.userData.correo).orderBy("creado","desc").startAfter(this.state.lastDocument).limit(10).get().then(snap => {
             let orders = [];
 
             snap.forEach(doc => {
                 let data = doc.data();
                 data.id = doc.id;
                 orders.push(data);
             })
 
             if(this._mounted){
                 this.setState({
                     orders:this.state.orders.concat(orders),
                     loadMore:snap.docs.length <10?false:true,
                     lastDocument:snap.docs[snap.docs.length - 1],
                     pending:false,
                     loadMorePending:false
                 })
             }
         })
         .catch(e => {
            console.log(e);
            if(this._mounted){
                this.setState({
                    loadMorePending:false,
                    loadMore:false
                })
            }
         })
    }

    getOrders = () => {
        if(this._mounted){
           this.setState({
              pending:true
           })
        }

        if(this._ordersListener !== undefined){
            this._ordersListener()//CLean the listener
        }
        let base = firebase.firestore().collection("ventas");

        if(this.state.search !== ""){
            base = base.where("palabrasClave","array-contains",String(this.state.search).toLowerCase());
        }

        this._ordersListener = base.where("vendedor","==",this.props.userData.correo).orderBy("creado","desc").limit(10).onSnapshot(snap => {
            let orders = [];

            snap.forEach(doc => {
                let data = doc.data();
                data.id = doc.id;
                orders.push(data);
            })

            if(this._mounted){
                this.setState({
                    orders:orders,
                    loadMore:snap.docs.length <10?false:true,
                    lastDocument:snap.docs[snap.docs.length - 1],
                    pending:false
                })
            }
        }, (e) => {
            console.log(e);
            if(this._mounted){
                this.setState({
                    pending:false,
                    loadMore:false
                })
            }
        })

    }



    loadProducts = () => {
        if(this._mounted){
            this.setState({
               
                pendingProducts:true,
                //products:[]
            })
        }

        let base =  firebase.firestore().collection("productos")

        if(this.state.searchProducts !== ""){
            base = base.where("palabrasClave","array-contains",String(this.state.searchProducts).toLowerCase())
        }
        base.orderBy("creado","desc").limit(10).get()
        .then(snap => {
            let products = []
           

           
            snap.forEach(doc => {
                let data = doc.data();
                data.id = doc.id
                products.push(data);
            })

            if(this._mounted){
                
                this.setState({
                    isLoading:false,
                    loadMoreProducts:products.length < 10?false:true,
                    lastDocumentProducts:snap.docs[snap.docs.length - 1],
                    products:products,
                    pendingProducts:false
                })
            }
       

        })
        .catch(e => {
            console.log(e);
            if(this._mounted){
                this.setState({
                 
                    pendingProducts:false
                })
            }
            this.props.addToast("Algo salió mal")
        })
    }


    loadMoreProducts = () => {
        if(this._mounted){
            this.setState({
                pendingLoadMoreProducts:true
            })
        }

        let base =  firebase.firestore().collection("productos")

        if(this.state.searchProducts !== ""){
            base = base.where("palabrasClave","array-contains",String(this.state.searchProducts).toLowerCase())
        }

        base.orderBy("creado","desc").startAfter(this.state.lastDocumentProducts).limit(10).get()
        .then(snap => {
       
                let products = []
                snap.forEach(doc => {
                    let data = doc.data();
                    data.id = doc.id;
                    products.push(data);
                })

                if(this._mounted){
                    this.setState({
                        pendingProducts:false,
                        products:this.state.products.concat(products),
                        loadMoreProducts:snap.docs.length < 10?false:true,
                        lastDocumentProducts:snap.docs[snap.docs.length - 1],
                        pendingLoadMoreProducts:false
                    })
                }
        
        })
        .catch(e => {
            console.log(e);
            if(this._mounted){
                this.setState({
                    pendingLoadMoreProducts:false
                })
            }
            this.props.addToast("Algo salió mal")
        })
    }

    componentDidMount(){
        this._mounted = true;

        this.getOrders();

        this.getIva()

        


        $(".special-search").on('keypress',(e) =>{
            if(e.which == 13) {
                this.getOrders();
            }
        });
    }

    componentWillUnmount(){
        this._mounted = false;

        if(this._ordersListener !== undefined){
            this._ordersListener()//Clean the listener
        }
    }

    render(){
        return <div className="container-fluid fade-in" style={{
            paddingLeft:this.props.device === "small"?"20px":this.props.navStatus === "expanded"?"340px":"120px", 
                paddingTop:this.props.device === "small"?"20px":"40px",
                paddingRight:this.props.device === "small"?"20px":"40px",
                paddingBottom:this.props.device === "small"?"20px":"40px"
            }}>

{                  this.state.completeLoading !==""?<CompleteLoading message={this.state.completeLoading}/>:null}
                    {this.state.isLoading?<Loading/>:null}
                <h2 className="text-center font-weight-bold">Pedidos</h2>


                {this.state.screen === 0?<>
                    <button className="btn btn-primary btn-block mt-4" onClick={() => {
                            if(this._mounted){
                                this.setState({
                                    screen:2
                                })
                            }
                            this.getClients();
                            this.loadProducts();
                        }}>
                            <i className="material-icons align-middle">add</i>
                            <span className="ml-2">Crear Pedido</span>
                        </button>

                <div className="input-group mb-3 mt-4">
  <input type="text" value={this.state.search} className="form-control special-search" placeholder="Search" onChange={e => {
      if(this._mounted){
          this.setState({
              search:e.currentTarget.value
          })
      }
  }}/>
  <div className="input-group-append">
    <button className="btn btn-primary" onClick={this.getOrders} type="submit"><i className="material-icons align-middle" style={{fontSize:"18px"}}>search</i></button>
  </div>
</div>

{this.state.pending === true?<div className="flex-left mt-2"><div className="spinner-border"></div></div>:
this.state.orders.length < 1?<p className="text-center mt-2">No hay órdenes</p>:(() => {
                        let elements = []
                      
                       for(let i =0; i < this.state.orders.length; i +=2){
                        
                           elements.push(<div className="row m-0" key={i}>
                               <div className="col-lg-6 m-0 p-2">
                                   <OrderItem
                                   
                                   changeState={(id, value) => {
                                       if(this._mounted){
                                           this.setState({
                                               completeLoading:"Actualizando Venta..."
                                           })
                                       }

                                     

                                       firebase.firestore().collection("usuarios").doc(id).update({
                                           activo:value
                                       })
                                       .then(() => {
                                           if(this._mounted){
                                               this.setState({
                                                   completeLoading:""
                                               })
                                           }

                                           this.props.addToast("Vendedor actualizado!")
                                       })
                                       .catch(e => {
                                           console.log(e);
                                           if(this._mounted){
                                               this.setState({
                                                   completeLoading:""
                                               })
                                           }
                                           this.props.addToast("Algo salió mal")
                                       })
                                   }}
                                    order={this.state.orders[i]} 

                                    accept={() => {
                                        if(this._mounted){
                                            this.setState({
                                                completeLoading:"Aprobando Venta"
                                            })
                                        }

                                        firebase.firestore().collection("ventas").doc(this.state.orders[i].id).update({
                                            estadoAprobacion:"aprobado"
                                        })
                                        .then(() => {
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }

                                            this.props.addToast("Venta Aprobada!")
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Algo salió mal");
                                        })
                                    }}

                                    delete={() => {
                                        if(this._mounted){
                                            this.setState({
                                                completeLoading:"Eliminando Venta"
                                            })
                                        }

                                        let batch = firebase.firestore().batch();

                                        for(let j =0; j < this.state.orders[i].productos; j++){
                                            batch.update(firebase.firestore().collection("productos").doc(this.state.orders[i].productos[j].idProducto), {
                                                actualizado:new Date(),
                                                disponibilidad:firebase.firestore.FieldValue.increment(this.state.orders[i].productos[j].cantidad)
                                            })
                                        }

                                       // batch.delete(firebase.firestore().collection("ventas").doc(this.state.orders[i]))
                                       batch.delete(firebase.firestore().collection("ventas").doc(this.state.orders[i].id))

                                        batch.commit()
                                        .then(() => {
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Venta Eliminada!")
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Algo salió mal");
                                        })
                                    }}

                                    deny={() => {
                                        if(this._mounted){
                                            this.setState({
                                                completeLoading:"Rechazando Venta"
                                            })
                                        }

                                        let batch = firebase.firestore().batch();

                                        for(let j =0; j < this.state.orders[i].productos; j++){
                                            batch.update(firebase.firestore().collection("productos").doc(this.state.orders[i].productos[j].idProducto), {
                                                actualizado:new Date(),
                                                disponibilidad:firebase.firestore.FieldValue.increment(this.state.orders[i].productos[j].cantidad)
                                            })
                                        }

                                       // batch.delete(firebase.firestore().collection("ventas").doc(this.state.orders[i]))
                                       batch.update(firebase.firestore().collection("ventas").doc(this.state.orders[i].id), {
                                        estadoAprobacion:"rechazado"
                                    })

                                        batch.commit()
                                        .then(() => {
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Venta Rechazada!")
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Algo salió mal");
                                        })
                                    }}
                                   
                                   view={() => {
                                    if(this._mounted){
                                        this.setState({
                                            data:this.state.orders[i],
                                            screen:1
                                        })
                                    }
                                }}
                                  

                                  
                                    />
                               </div>
                               
                               <div className="col-lg-6 p-2 m-0">
                                   {i +1 === this.state.orders.length?null:
                                   <OrderItem

                                    view={() => {
                                        if(this._mounted){
                                            this.setState({
                                                data:this.state.orders[i+1],
                                                screen:1
                                            })
                                        }
                                    }}

                                    changeState={(id, value) => {
                                        if(this._mounted){
                                            this.setState({
                                                completeLoading:"Actualizando Vendedor..."
                                            })
                                        }
 
                                      
 
                                        firebase.firestore().collection("usuarios").doc(id).update({
                                            activo:value
                                        })
                                        .then(() => {
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
 
                                            this.props.addToast("Vendedor actualizado!")
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Algo salió mal")
                                        })
                                    }}

                                   
                                   
                                    order={this.state.orders[i+1]}

                                    accept={() => {
                                        if(this._mounted){
                                            this.setState({
                                                completeLoading:"Aprobando Venta"
                                            })
                                        }

                                        firebase.firestore().collection("ventas").doc(this.state.orders[i+1].id).update({
                                            estadoAprobacion:"aprobado"
                                        })
                                        .then(() => {
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }

                                            this.props.addToast("Venta Aprobada!")
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Algo salió mal");
                                        })
                                    }}

                                    delete={() => {
                                        if(this._mounted){
                                            this.setState({
                                                completeLoading:"Eliminando Venta"
                                            })
                                        }

                                        let batch = firebase.firestore().batch();

                                        for(let j =0; j < this.state.orders[i+1].productos; j++){
                                            batch.update(firebase.firestore().collection("productos").doc(this.state.orders[i+1].productos[j].idProducto), {
                                                actualizado:new Date(),
                                                disponibilidad:firebase.firestore.FieldValue.increment(this.state.orders[i+1].productos[j].cantidad)
                                            })
                                        }

                                        batch.delete(firebase.firestore().collection("ventas").doc(this.state.orders[i+1].id))

                                        batch.commit()
                                        .then(() => {
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Venta Eliminada!")
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Algo salió mal");
                                        })
                                    }}

                                    deny={() => {
                                        if(this._mounted){
                                            this.setState({
                                                completeLoading:"Rechazando Venta"
                                            })
                                        }

                                        let batch = firebase.firestore().batch();

                                        for(let j =0; j < this.state.orders[i+1].productos; j++){
                                            batch.update(firebase.firestore().collection("productos").doc(this.state.orders[i+1].productos[j].idProducto), {
                                                actualizado:new Date(),
                                                disponibilidad:firebase.firestore.FieldValue.increment(this.state.orders[i+1].productos[j].cantidad)
                                            })
                                        }

                                       batch.update(firebase.firestore().collection("ventas").doc(this.state.orders[i+1].id), {
                                           estadoAprobacion:"rechazado"
                                       })

                                        batch.commit()
                                        .then(() => {
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Venta Rechazada!")
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Algo salió mal");
                                        })
                                    }}
                                    
                                    />}


                               </div>
                           </div>)
                       }

                       return elements
                    })()}

{this.state.loadMore === true?<div className="mt-4 flex-center">
                       {this.state.loadMorePending?<div className="spinner-border"></div>:<a onClick={e => {
                           e.preventDefault();
                           this.loadMoreOrders()
                       }}>Cargar más</a>}
                    </div>:null}
                    </>:null}

                    {this.state.screen === 2?<div className="container-fluid mt-4 show-in">
                        <div className="flex-between">
                    <button className="btn btn-danger btn-sm" onClick={() => {
                            if(this._mounted){
                                this.setState({
                                    screen:0
                                }, () => {
                                    
                                })
                            }
                            
                        }}>
                            <i className="material-icons align-middle">arrow_back</i>
                            <span className="ml-2">Atras</span>
                        </button>

                        <button className="btn btn-primary btn-sm" onClick={() => {
                            this.createOrder();
                        }}>
                            <i className="material-icons align-middle">add</i>
                            <span className="ml-2">Crear</span>
                        </button>
                        </div>

                        <div className="row mx-0 create-order-section">
                            <div className="col-xl-4 mt-5">
                                <h4 className="mb-3">Clientes</h4>
                            <div className="input-group sticky-top">
  <input type="text" value={this.state.searchClients} className="form-control special-search" placeholder="Búsqueda inteligente" onChange={e => {
      if(this._mounted){
          this.setState({
              searchClients:e.currentTarget.value
          })
      }
  }}/>
  <div className="input-group-append">
    <button className="btn btn-primary" onClick={this.getClients} type="submit"><i className="material-icons align-middle" style={{fontSize:"18px"}}>search</i></button>
  </div>
</div>


                        {this.state.pendingClients === true?<div className="flex-center mt-3"><div className="spinner-border"></div></div>:
                        this.state.clients.length < 1?<p className="text-center mt-2">No hay clientes</p>:this.state.clients.map((e,i) => {
                            return <ClientItem
                            key={i}
                            selected={this.state.clientSelected !== null?this.state.clientSelected.id === e.id:false}
                            onClick={() => {
                                if(this._mounted){
                                    this.setState({
                                        clientSelected:e
                                    })
                                }
                            }}
                            createOrder={true}
                             user={e} 
                            
                            view={() => {
                             if(this._mounted){
                                 this.setState({
                                     data:e,
                                     screen:2
                                 })
                             }
                         }}
                            

                            
                             />
                        })}

{this.state.loadMoreClients === true?<div className="mt-4 flex-center">
                       {this.state.loadMorePendingClients?<div className="spinner-border"></div>:<a onClick={e => {
                           e.preventDefault();
                           this.loadMoreClients();
                       }}>Cargar más</a>}
                    </div>:null}

                            </div>

                            <div className="col-xl-4 mt-5">
                                <h4 className="mb-3">Productos</h4>
                            <div className="input-group sticky-top">
  <input type="text" className="form-control special-search" value={this.state.searchProducts} placeholder="Búsqueda inteligente" onChange={e => {
      if(this._mounted){
          this.setState({
              searchProducts:e.currentTarget.value
          })
      }
  }}/>
  <div className="input-group-append">
    <button className="btn btn-primary" onClick={this.loadProducts} type="submit"><i className="material-icons align-middle" style={{fontSize:"18px"}}>search</i></button>
  </div>
</div>

    <div className="mt-3">
        {this.state.pendingProducts?<div className="flex-center">
            <div className="spinner-border"></div>
        </div>:this.state.products.length < 1?<p className="text-center">No hay productos</p>:
        this.state.products.map((e,i) => {
            return <div key={i} className={`${i===0?"mt-0":"mt-2"} product-item shadow-sm`}>
                <div className="row mx-0">
                    <div className="col-4">
                        <div className="image" style={{
                            backgroundImage:`url(${e.imagenUrl !== "" && e.imagenUrl !== undefined?e.imagenUrl:product_placeholder})`,
                            backgroundPosition:"center",
                            backgroundRepeat:"no-repeat",
                            backgroundSize:"cover"
                        }}></div>
                    </div>
                    <div className="col-8">
                        <p className="mb-0 name">{e.nombre}</p>
                        <p className="mb-0 mt-2 codigo">{e.codigo}</p>
                        <p className="mb-0 mt-2 marca">{e.marca}</p>

                        <p className="mb-0 mt-2 price">${Math.round(formatNumber(e.precioUSD)*100)/100}</p>
                    </div>
                </div>

                {(() => {
                    let cart = [...this.state.cart];

                    let flag = 0;

                    let newIndex;

                    for(let i=0; i < cart.length; i++){
                        if(cart[i].idProducto === e.id){
                            flag = 1;
                            newIndex = i;
                            break;
                        }
                    }

                    if(newIndex !== undefined){
                        return <div className="flex-end add-to-cart">
                            <button className="btn" onClick={() => {
                                let cart = [...this.state.cart];

                                if(cart[newIndex].cantidad === 1){
                                    cart.splice(newIndex, 1);
                                

                                }else {
                                    cart[newIndex].cantidad = cart[newIndex].cantidad - 1;
                                }

                                if(this._mounted){
                                    this.setState({
                                        cart:cart
                                    })
                                }
                            }}>
                                <i className="material-icons align-middle">remove</i>
                            </button>
                            <input className="form-control" style={{
                                textAlign:"center",
                                maxWidth:"40px",
                                padding:"5px"
                            }} value={this.state.cart[newIndex].cantidad} onChange={(e) => {
                                let cart = [...this.state.cart];

                                
                                cart[newIndex].cantidad = Number(e.currentTarget.value);

                                if(!isNaN(Number(cart[newIndex].cantidad))){
                                if(this._mounted){
                                    this.setState({
                                        cart:cart
                                    })
                                }
                            }
                            }}/>
                            <button className="btn" onClick={() => {
                                let cart = [...this.state.cart];

                                cart[newIndex].cantidad = cart[newIndex].cantidad + 1;

                                if(this._mounted){
                                    this.setState({
                                        cart:cart
                                    })
                                }
                            }}>
                                <i className="material-icons align-middle">add</i>
                            </button>
                        </div>
                    }else {
                        return <button className="btn add-to-cart" onClick={() => {
                            let cart = [...this.state.cart];

                            cart.push({
                                idProducto:e.id,
                                cantEmp:String(e.cantEmp),
                                codigo:e.codigo,
                                descripcion:"Sin definir",
                                descuento:0,
                                marca:e.marca,
                                minCantVenta:e.minCantVenta,
                                nombre:e.nombre,
                                precioUSD:e.precioUSD,
                                precioUnidad:0,
                                total:0,
                                totalUSD:0,
                                cantidad:1,
                                imagenUrl:e.imagenUrl
                            })

                            if(this._mounted){
                                this.setState({
                                    cart:cart
                                })
                            }
                        }}>
                            <i className="material-icons align-middle">add_shopping_cart</i>
                        </button>
                    }
                })()}
            </div>
        })}

        {this.state.pendingLoadMoreProducts?<div className="mt-3 flex-center">
            <div className="spinner-border"></div>
        </div>:this.state.loadMoreProducts?<div className="flex-center mt-3">
            <button className="btn" onClick={() => {
                this.loadMoreProducts()
            }}>
                <span className="mr-2">Cargar más</span>
                <i className="material-icons align-middle">add</i>
            </button>
        </div>:null}
    </div>
                            </div>

                            <div  className="col-xl-4 mt-5">
                                <h4 className="mb-3">Pedido</h4>

                                <div className="form-group">
                                    <label>Forma de pago</label>
                                    <select className="custom-select custom-select-1" value={this.state.paymentType} onChange={e => {
                                        if(this._mounted){
                                            this.setState({
                                                paymentType:e.currentTarget.options[e.currentTarget.options.selectedIndex].value
                                            })
                                        }
                                    }}>
                                        <option value={"0"}>Pago en dólares</option>
                                        <option value={"1"}>Pago en Bolivares</option>
                                        <option value={"2"}>Contado</option>
                                        <option value={"3"}>Crédito</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                <label>Comentario</label>
                                <textarea className="form-control" value={this.state.comment} onChange={e => {
                                    if(this._mounted){
                                        this.setState({
                                            comment:e.currentTarget.value
                                        })
                                    }
                                }}></textarea>
                                </div>

                                <div className="row mx-0">
                                    <div className="col-6 pl-0">
                                        <p className="mb-0">Incluye iva</p>
                                    </div>

                                    <div className="col-6 mr-0">
                                    <Switch onColor="#3daaaf" checkedIcon={false} uncheckedIcon={false} onChange={(value) => {
                //console.log(value);
               if(this._mounted){
                   this.setState({
                       iva:value
                   })
               }
            }} checked={this.state.iva} />
                                    </div>
                                </div>

                                <div className="row mx-0 mt-3">
                                    <div className="col-6 pl-0">
                                        <p className="mb-0">Subtotal</p>
                                    </div>

                                    <div className="col-6 pr-0">
                                       <p className="mb-0 price">${this.state.ivaValue !== null?(() => {
                                            let total= 0;

                                            for(let i=0; i < this.state.cart.length; i++){
                                                total = total + (this.state.cart[i].precioUSD * this.state.cart[i].cantidad)
                                            }

                                           // formatNumber(Math.round((total + total*this.state.ivaValue)*100)/100)

                                            if(this.state.iva === true){
                                                return formatNumber(Math.round((total) * 100)/100)
                                            }else {
                                                return formatNumber(Math.round(total * 100)/100)

                                            }


                                        })():null}
                                        </p>
                                    </div>
                                </div>

                                

                                <div className="row mx-0 mt-3">
                                    <div className="col-6 pl-0">
                                        <p className="mb-0">Total</p>
                                    </div>

                                    <div className="col-6 pr-0">
                                       <p className="mb-0 price">${this.state.ivaValue !== null?(() => {
                                            let total= 0;

                                            for(let i=0; i < this.state.cart.length; i++){
                                                total = total + (this.state.cart[i].precioUSD * this.state.cart[i].cantidad)

                                             
                                            }

                                            

                                            if(this.state.iva === true){
                                                return formatNumber(Math.round((total + total*this.state.ivaValue)*100)/100)
                                            }else {
                                                return formatNumber(Math.round(total * 100)/100)
                                            }


                                        })():null}
                                        </p>
                                    </div>
                                </div>

                                {this.state.clientSelected?<h5 className="mt-4">Cliente</h5>:null}
                                {this.state.clientSelected?<ClientItem
                                createOrder={true}
                            
                             user={this.state.clientSelected} 
                        
                            
                             />:null}

                           {this.state.cart.length > 0?<h5 className="mt-4">Productos Seleccionados</h5>:null}

                             {this.state.cart.map((e,i) => {
                                 return <div key={i} className={`${i===0?"mt-3":"mt-2"} product-item shadow-sm`}>
                                 <div className="row mx-0">
                                     <div className="col-4">
                                         <div className="image" style={{
                                             backgroundImage:`url(${e.imagenUrl !== "" && e.imagenUrl !== undefined?e.imagenUrl:product_placeholder})`,
                                             backgroundPosition:"center",
                                             backgroundRepeat:"no-repeat",
                                             backgroundSize:"cover"
                                         }}></div>
                                     </div>
                                     <div className="col-8">
                                         <p className="mb-0 name">{e.nombre}</p>
                                         <p className="mb-0 mt-2 codigo">{e.codigo}</p>
                                         <p className="mb-0 mt-2 marca">{e.marca}</p>

                                         <p className="mb-0 mt-2 price">${Math.round(formatNumber(e.precioUSD)*100)/100}</p>
                                     </div>
                                 </div>
                 
                                 {(() => {
                                     let cart = [...this.state.cart];
                 
                                     let flag = 0;
                 
                                     let newIndex;
                 
                                     for(let i=0; i < cart.length; i++){
                                         if(cart[i].idProducto === e.idProducto){
                                             flag = 1;
                                             newIndex = i;
                                             break;
                                         }
                                     }
                 
                                     if(newIndex !== undefined){
                                         return <div className="flex-end add-to-cart">
                                             <button className="btn" onClick={() => {
                                                 let cart = [...this.state.cart];
                 
                                                 if(cart[newIndex].cantidad === 1){
                                                     cart.splice(newIndex, 1);
                                                 
                 
                                                 }else {
                                                     cart[newIndex].cantidad = cart[newIndex].cantidad - 1;
                                                 }
                 
                                                 if(this._mounted){
                                                     this.setState({
                                                         cart:cart
                                                     })
                                                 }
                                             }}>
                                                 <i className="material-icons align-middle">remove</i>
                                             </button>
                                             <input className="form-control" style={{
                                                 textAlign:"center",
                                                 maxWidth:"40px",
                                                 padding:"5px"
                                             }} value={this.state.cart[newIndex].cantidad} onClick={(e) => {
                                                 let cart = [...this.state.cart];
                 
                                                 
                                                 cart[newIndex].cantidad = Number(e.currentTarget.value);
                 
                                                 if(!isNaN(Number(cart[newIndex].cantidad))){
                                                 if(this._mounted){
                                                     this.setState({
                                                         cart:cart
                                                     })
                                                 }
                                             }
                                             }}/>
                                             <button className="btn" onClick={() => {
                                                 let cart = [...this.state.cart];
                 
                                                 cart[newIndex].cantidad = cart[newIndex].cantidad + 1;
                 
                                                 if(this._mounted){
                                                     this.setState({
                                                         cart:cart
                                                     })
                                                 }
                                             }}>
                                                 <i className="material-icons align-middle">add</i>
                                             </button>
                                         </div>
                                     }else {
                                         return <button className="btn add-to-cart" onClick={() => {
                                             let cart = [...this.state.cart];
                 
                                             cart.push({
                                                 idProducto:e.id,
                                                 cantEmp:e.cantEmp,
                                                 codigo:e.codigo,
                                                 descripcion:"Sin definir",
                                                 descuento:0,
                                                 marca:e.marca,
                                                 minCantVenta:e.minCantVenta,
                                                 nombre:e.nombre,
                                                 precioUSD:e.precioUSD,
                                                 precioUnidad:0,
                                                 total:0,
                                                 totalUSD:0,
                                                 cantidad:1,
                                                 imagenUrl:e.imagenUrl
                                             })
                 
                                             if(this._mounted){
                                                 this.setState({
                                                     cart:cart
                                                 })
                                             }
                                         }}>
                                             <i className="material-icons align-middle">add_shopping_cart</i>
                                         </button>
                                     }
                                 })()}
                             </div>
                             })}
                            </div>
                        </div>
                    </div>:null}

                    {this.state.screen === 1?<div className="container-fluid mt-4 show-in">
                    <div className="flex-left">
                        <button className="btn btn-danger btn-sm" onClick={() => {
                            if(this._mounted){
                                this.setState({
                                    screen:0
                                }, () => {
                                    
                                })
                            }
                            
                        }}>
                            <i className="material-icons align-middle">arrow_back</i>
                            <span className="ml-2">Atras</span>
                        </button>
                    </div>
                    

                    <h3 className="mt-5">Información</h3>

                    <div className="white-card mt-3">
                    <div className="row">
                        <div className="col-sm-6 m-0">
                            <label>Cliente</label>
                            <h5>{this.state.data.nombreCliente}</h5>
                        </div>
                        <div className="col-sm-6 m-0">
                            <label>Vendedor</label>
                            <h5>{this.state.data.vendedor}</h5>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-sm-6 m-0">
                            <label>Forma de Pago</label>
                            <h5>{this.state.data.formaPago === 0?"Pago en dólares":this.state.data.formaPago === 1?"Pago en bolivares":this.state.formaPago === 2?"Contado":"Crédito"}</h5>
                        </div>

                        <div className="col-sm-6 m-0">
                            <label>Creado</label>
                            <h5>{this.state.data.creado.toDate().getDate() + "-" + (this.state.data.creado.toDate().getMonth() + 1) + "-" + this.state.data.creado.toDate().getFullYear() + " " + this.state.data.creado.toDate().getHours() + ":" + this.state.data.creado.toDate().getMinutes()}</h5>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-sm-6 m-0">
                            <label>Estado</label>
                            <h5 className={`${this.state.data.estadoAprobacion === "pendiente"?"primary-text":this.state.data.estadoAprobacion === "aprobado"?"text-success":"text-danger-2"}`}>{capitalize(this.state.data.estadoAprobacion)}</h5>
                        </div>

                       
                    </div>
                    </div>

                    <h3 className="mt-4">Total</h3>

                    <div className="white-card mt-3">
                    <div className="row mx-0">
                        <div className="col-sm-6 m-0">
                            <label>Sub Total</label>
                            <h5>{Math.round(this.state.data.subTotal * 100)/100}</h5>
                        </div>
                        <div className="col-sm-6 m-0">
                            <label>Total</label>
                            <h5>{Math.round(this.state.data.total *100)/100}</h5>
                        </div>
                    </div>

                    </div>


                    <h4 className="mt-4">Información Adicional</h4>

                    <div className="white-card mt-3">
                        <div className="row mx-0">
                            <div className="col-sm-12">
                                <label>Comentarios</label>
                                <h5>{(this.state.data.comentario !== undefined && this.state.data.comentario)?this.state.data.comentario:"No especificado"}</h5>
                            </div>
                        </div>
                    </div>

                    

                    <h3 className="mt-4">Productos</h3>

                    <div className="table-responsive mt-3">
                        <table className="table">
                            <thead>
              <th>Código</th>
              <th>Nombre</th>
              <th>Precio USD</th>
              <th>Cantidad</th>
             
                            </thead>
                            <tbody>
                                {this.state.data.productos.map((e,i) => {
                                    return <tr key={i}>
                                        <td>
                      {e.codigo}
                  </td>
                  <td>
                      {e.nombre}
                  </td>
                  <td>
                      {e.precioUSD}
                  </td>
                  <td>
                      {e.cantidad}
                  </td>
                  
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>:null}
        </div>
    }
}

